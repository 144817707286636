import { PropsWithChildren } from "react"

import { Button } from "@/Components/Buttons/index"
import { ButtonProps, ButtonVariant } from "@/Components/Buttons/types"

const PrimaryButton = ({ ...otherProps }: PropsWithChildren<ButtonProps>) => {
  return (
    <>
      <Button variant={ButtonVariant.primary} {...otherProps} />
    </>
  )
}

export default PrimaryButton
